<template>
  <div
    v-e2e="'registration-form-common'"
    :class="['asf-registration-form', { 'm-general-error': errors?.__general__ }]"
  >
    <section>
      <AsfHeading tag="h6" appearance="h6">
        {{ $t('loginPage.benefitsListTitle') }}
      </AsfHeading>
      <AsfList tag="ul" class="mt-4">
        <AsfListItem
          v-for="(benefit, index) of benefits"
          :key="'benefit-' + index"
          class="before:content-['•'] before:inline-block before:text-2xl before:mr-2"
        >
          {{ $t(benefit) }}
        </AsfListItem>
      </AsfList>
    </section>
    <AsfForm
      id="registration-form"
      class="mt-8"
      v-bind="form"
      :error="errors"
      @form:submitted="$emit('form:submitted', $event)"
    >
      <template #form-buttons>
        <AsfButton type="submit" class="asf-button-primary" v-e2e="'create-account-button'">
          {{ $t('loginPage.form.createAccount') }}
        </AsfButton>
      </template>
      <template #agreeToPrivacy-slot-label>
        <AccountRegistrationAgreement />
      </template>
    </AsfForm>
  </div>
</template>
<script lang="ts" setup>
import type { AsfFormProps } from '@ui/types'

type RegistrationFormProps = {
  form: AsfFormProps
  errors?: AsfFormProps['error']
}

defineProps<RegistrationFormProps>()

const benefits = [
  'loginPage.benefits.speed',
  'loginPage.benefits.track',
  'loginPage.benefits.look',
  'loginPage.benefits.save'
]
</script>
