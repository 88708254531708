<template>
  <div id="login" class="asf-login asf-page-top-indent asf-page-bottom-indent" v-e2e="'login-form-common'">
    <AsfWrapper>
      <section>
        <AsfDivider position="center">
          <AsfIcon name="order-check" size="16" class="text-primary" />
        </AsfDivider>
        <AsfHeading tag="h2" appearance="h6" has-subtitle class="text-center asf-login__title">
          {{ $t('loginPage.heading.title') }}
          <template #content-subtitle>
            {{ $t('loginPage.heading.subtitleStart') }}
            <AsfLink :link="{ name: 'order-trackform' }" class="font-medium underline hover:no-underline">
              {{ $t('loginPage.heading.subtitleLink') }}
            </AsfLink>
            {{ $t('loginPage.heading.subtitleEnd') }}
          </template>
        </AsfHeading>
        <AsfDivider class="mb-4" />
      </section>
      <AsfTabs class="asf-grid-layout" :tabs="tabs" @active="handleActiveTab">
        <template #content>
          <div :class="tabContentClasses" tabindex="0" role="tabpanel">
            <AccountLoginForm
              v-if="activeTab === tabs[0]?.id"
              :errors="loginError"
              :form="loginFormDefinition"
              @form:submitted="handleSubmitLogin"
            />
            <AccountRegistrationForm
              v-if="activeTab === tabs[1]?.id"
              :errors="registerError"
              :form="registrationFormDefinition"
              @form:submitted="handleSubmitRegister"
            />
          </div>
        </template>
      </AsfTabs>
    </AsfWrapper>
  </div>
</template>

<script lang="ts" setup>
import { getFormByCountry } from 'shared/config/dynamicForms'

useCache('account')
const { getURL } = useURL()
const { setSeoLogin } = useSEO()
const { t } = useI18n()
const { register, login, error, getDefaultCountry } = useCustomer()
const { reportPageView } = useTagManager()

useAlternativePageUrls().setupUrls((locale) => getURL({ name: 'login' }, locale))

reportPageView('account')
setSeoLogin()
const tabs = [
  { id: 'sign-in', title: `${t('loginPage.form.signIn')}` },
  { id: 'create-account', title: `${t('loginPage.form.createAccount')}` }
]

const tabContentClasses = [
  'col-start-1 col-span-12',
  'md:col-start-3 md:col-span-8',
  'lg:col-start-4 lg:col-span-6',
  'xl:col-start-5 xl:col-span-4'
]
const country = ref(getDefaultCountry())
const activeTab = ref(tabs[0]?.id)
const handleActiveTab = (id: string) => (activeTab.value = id)

const loginModel = reactive<any>({})
const registrationModel = reactive<any>({})

const loginFormDefinition = {
  id: 'login',
  name: 'login',
  fields: getFormByCountry('login', 'any'),
  formModel: loginModel
}

const registrationFormDefinition = {
  id: 'registration',
  name: 'registration',
  fields: getFormByCountry('registration', country.value),
  formModel: registrationModel
}

const handleSubmitLogin = async () => {
  await login(loginModel)
  if (!error.value?.login) {
    // @ts-expect-error
    navigateTo(getURL({ name: 'account' }))
  }
}
const handleSubmitRegister = async () => {
  await register(registrationModel)
  if (!error.value?.register) {
    // @ts-expect-error
    navigateTo(getURL({ name: 'account' }))
  }
}

const loginError = computed(() => error.value.login)
const registerError = computed(() => error.value.register)
</script>
<style lang="postcss">
@import 'assets/css/pages/login.css';
</style>
