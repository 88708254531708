<template>
  <div class="asf-tabs">
    <div class="asf-tabs asf-tabs__buttons" role="tablist">
      <AsfTab
        v-for="{ id, title, iconName } in tabs"
        :id="id"
        :key="title"
        v-e2e="`ta-${id}`"
        :icon-name="iconName"
        :type="type"
        :title="title"
        :active-tab="activeTab || initialActiveTab"
        :class="{ 'm-margin': isWithIcons }"
        @toggle="handleToggle"
      />
    </div>

    <slot name="content" />
  </div>
</template>
<script lang="ts">
import type { AsfTabsProps } from '@ui/types'

export default defineComponent({
  name: 'AsfTabs',
  props: {
    /**
     * Defines variant of the tabs
     */
    type: { type: String as PropType<AsfTabsProps['type']>, default: 'original' },
    /**
     * Defines initial active tab
     */
    initialActive: { type: Number as PropType<AsfTabsProps['initialActive']>, default: 1 },
    /**
     * Defines tabs array
     */
    tabs: { type: Array as PropType<AsfTabsProps['tabs']>, required: true }
  },
  setup(props, context) {
    const initialActiveTab = ref(props.tabs[(props.initialActive || 1) - 1]?.id || props.tabs[0]?.id || '')
    const isWithIcons = props.type === 'shipping'
    const activeTab = ref(initialActiveTab)

    const handleToggle = (id: string) => {
      activeTab.value = id
      context.emit('active', id)
    }

    return {
      activeTab,
      handleToggle,
      initialActiveTab,
      isWithIcons
    }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/Tabs/Tabs.css';
</style>
